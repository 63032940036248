import { AccessControlContext, AccessControlUpdateContext } from '../../contexts/AccessControlContext';
import { ModalContext, ModalUpdateContext } from '../../contexts/ModalContext';
import AccessControlService from '../../services/AccessControlService';
import React from 'react';

interface UseAccessControlNewUserStore {
	setIsNewUserModalOpen: (open: boolean) => void;
	isNewUserModalOpen: boolean;
	setUsername: (data: string) => void;
	username: string;
	setPassword: (data: string) => void;
	setEmail: (data: string) => void;
	email: string;
	password: string;
	roles: string[];
	selectedRoles: string[];
	setSelectedRoles: (value: string[]) => void;
	saveNewUser: () => void;
	error: string;
	setError: (value: string) => void;
}

const useAccessControlNewUser = (): UseAccessControlNewUserStore => {
	const { isNewUserModalOpen } = React.useContext(ModalContext);
	const { setIsNewUserModalOpen } = React.useContext(ModalUpdateContext);
	const { myRoles } = React.useContext(AccessControlContext);
	const { fetchUsers } = React.useContext(AccessControlUpdateContext);
	const [username, setUsername] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [error, setError] = React.useState('');

	const roles = myRoles && myRoles.length ? Object.keys(myRoles[0].GetRoles).filter(role => ((role !== 'info') && (role !== 'status'))) : [];

	const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);

	const cleanModalState = (): void => {
		setUsername('');
		setPassword('');
		setEmail('');
		setSelectedRoles([]);
		setError('');
	};

	const openModal = (open: boolean): void => {
		if (!open) {
			cleanModalState();
		}

		setIsNewUserModalOpen(open);
	};

	const updateUsername = (data: string): void => {
		setUsername(data);
	};

	const updatePassword = (data: string): void => {
		setPassword(data);
	};

	const updateEmail = (data: string): void => {
		setEmail(data);
	};

	const saveNewUser = async (): Promise<void> => {
		try {
			if (username.startsWith('_')) {
				setError('Username should not start with underscore character');

				return;
			}

			if (/\s/.test(username)) {
				setError('Username should not include any white space');

				return;
			}

			if (username.length < 4) {
				setError('Username should have at least 4 characters');

				return;
			}

			if (password.length < 10 || password.length > 20) {
				setError('Password should have between 10 and 20 characters');

				return;
			}

			const response = await AccessControlService.addUserToDataBase({
				username,
				password,
				roles: selectedRoles,
				email,
			});

			if (response.status === -1) {
				setError(response.info);
			} else {
				setError('');
				await fetchUsers();
				openModal(false);
			}
		} catch {
			setError('An error occurred while adding user');
		}
	};

	return {
		setIsNewUserModalOpen: openModal,
		isNewUserModalOpen,
		username,
		setUsername: updateUsername,
		password,
		setPassword: updatePassword,
		setEmail: updateEmail,
		email,
		roles,
		selectedRoles,
		setSelectedRoles,
		saveNewUser,
		error,
		setError,
	};
};

export default useAccessControlNewUser;
