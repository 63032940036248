import axios, { AxiosResponse } from 'axios';
import ApiAddressService from '../services/ApiAddressService';
import { convertToFormData } from '../helpers/request';

export const fetchSimilaritySearch = async (uniqueId: string, descriptorSet: string, kNeighbors: number): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const descriptorSets = [];
		const descriptorSetQuery = [
			{
				FindImage: {
					_ref: 1,
					blobs: false,
					constraints: {
						_uniqueid: [
							'==',
							uniqueId,
						],
					},
				},
			},
			{
				FindDescriptor: {
					_ref: 2,
					is_connected_to: {
						ref: 1,
					},
					blobs: false,
				},
			},
			{
				FindDescriptorSet: {
					is_connected_to: {
						ref: 2,
					},
					results: {
						list: [
							'_name',
						],
					},
				},
			},
		];
		const descriptorSetResponse = await axios.post(`${apiUrl}/`, convertToFormData(descriptorSetQuery));

		if (descriptorSetResponse.data.json &&
			descriptorSetResponse.data.json[2] &&
			descriptorSetResponse.data.json[2].FindDescriptorSet &&
			descriptorSetResponse.data.json[2].FindDescriptorSet.entities &&
			descriptorSetResponse.data.json[2].FindDescriptorSet.entities.length > 0) {
			for (const dss of descriptorSetResponse.data.json[2].FindDescriptorSet.entities) {
				descriptorSets.push(dss._name);
			}

			if (!descriptorSet) {
				descriptorSet = descriptorSets[0];
			}
		} else {
			throw ({
				name: 'Error',
				message: 'DescriptorSet not found',
			});
		}

		const descriptorQuery = [
			{
				FindImage: {
					_ref: 1,
					blobs: false,
					constraints: {
						_uniqueid: [
							'==',
							uniqueId,
						],
					},
				},
			},
			{
				FindDescriptor: {
					is_connected_to: {
						ref: 1,
					},
					set: descriptorSet,
					limit: 1,
					blobs: true,
				},
			},
		];
		const descriptorResponse = await axios.post(`${apiUrl}/`, convertToFormData(descriptorQuery));

		if (descriptorResponse.data.blobs.length == 1) {
			const descriptor = descriptorResponse.data.blobs[0];
			const imageQuery = [
				{
					FindDescriptor: {
						_ref: 1,
						k_neighbors: kNeighbors,
						set: descriptorSet,
					},
				},
				{
					FindImage: {
						is_connected_to: {
							ref: 1,
						},
						blobs: true,
						results: {
							all_properties: true,
						},
					},
				},
			];
			const imageResponse = await axios.post(`${apiUrl}/`, convertToFormData(imageQuery, [descriptor]));

			imageResponse.data.descriptors = descriptorSets;

			return imageResponse;
		}

		throw ({
			name: 'Error',
			message: 'Descriptor not found',
		});
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};

export const fetchSimilaritySearchQuery = async (uniqueId: string): Promise<AxiosResponse> => {
	const apiUrl = ApiAddressService.getApiUrl();

	if (apiUrl) {
		const query = [{
			FindImage: {
				_ref: 1,
				blobs: false,
				constraints: {
					_uniqueid: [
						'==',
						uniqueId,
					],
				},
			},
		},
		{
			FindDescriptor: {
				is_connected_to: {
					ref: 1,
				},
				_ref: 2,
				blobs: false,
			},
		},
		{
			FindDescriptorSet: {
				is_connected_to: {
					ref: 2,
				},
				results: {
					list: [
						'_name',
					],
				},
			},
		}];

		return axios.post(`${apiUrl}/`, convertToFormData(query));
	}

	throw ({
		name: 'Error',
		message: 'API url not defined',
	});
};
